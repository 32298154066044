
/**
 * @name: 销售管理-自营商品可售库存管理
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 销售管理-自营商品可售库存管理
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import type { ICrudOption } from "@/types/m-ui-crud"
import {
  stockPageApi,
  stockModifyAPi
} from '@/apis/sale/stock'
import {
  ISaleStockParams,
  ISaleStock
} from '@/apis/sale/stock/types'
import { IProductSort } from "@/apis/product/sort/types";
import { sortPageApi } from "@/apis/product/sort";

@Component({})
export default class saleStock extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ISaleStock[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ISaleStockParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: '',
        type: 'selection',
        width: 50,
        align: 'center'
      },
      {
        "label": "sku编号",
        "prop": "productSkuId",
        "align": "center",
        "width": 180,
        "search": true
      },
      {
        label: '商品',
        prop: 'productName',
        search: true,
        hide: true,
        placeholder: '商品编号/商品名称'
      },
      {
        "label": "商品编号",
        "prop": "productId",
        "align": "center",
        "width": 180
      },
      {
        label: '商品名称',
        prop: 'productName',
        align: 'center',
        overHidden: true
      },
      {
        label: '规格属性',
        prop: 'spData',
        align: 'center',
        overHidden: true,
        slot: true
      },
      {
        label: '渠道来源',
        prop: 'supplyType',
        type: 'select',
        dicData: [
          {
            label: "京东",
            value: 1
          },
          {
            label: "星链",
            value: 2
          },
          {
            label: '平台测试',
            value: 3
          },
          {
            label: "云商特卖",
            value: 4
          },
          {
            label: "厂商特卖",
            value: 5
          },
          {
            label: "天猫",
            value: 6
          },
          {
            label: "微唯宝特卖",
            value: 7
          },
          {
            label: "云链专供",
            value: 8
          },
          {
            label: "三方仓储",
            value: 9
          },
          {
            label: "华南一仓",
            value: 10
          },
          {
            label: "华南二仓",
            value: 11
          },
          {
            label: "华南二仓",
            value: 12
          },
          {
            label: "3C家电",
            value: 13
          },
          {
            label: "新疆专场",
            value: 14
          },
          {
            label: "西藏专场",
            value: 15
          },
          {
            label: "内蒙专场",
            value: 16
          },
          {
            label: "华东一仓",
            value: 17
          },
          {
            label: "企业专属",
            value: 18
          },
          {
            label: "云采渠道",
            value: 19
          },
          {
            label: "华东二仓",
            value: 20
          },
          {
            label: "华东三仓",
            value: 21
          },
          {
            label: "自营",
            value: 88
          },
        ],
        align: 'center',
      },
      {
        label: '商品分类',
        prop: 'sortId',
        search: true,
        hide: true,
        searchSlot: true
      },
      {
        label: '商品分类',
        prop: 'sortName',
        align: "center"
      },
      {
        "label": "零售价（元）",
        "prop": "retailPrice",
        "align": "center"
      },
      {
        "label": "商品图片",
        "prop": "skuPic",
        "align": "center",
        "type": "image",
        imgSuffix: ';'
      },
      {
        "label": "可售库存",
        "prop": "stock",
        "align": "center",
        slot: true,
        width: 150
      },
      {
        label: '状态',
        prop: 'status',
        type: 'select',
        align: "center",
        search: true,
        dicData: [
          {
            label: '上架',
            value: 1
          },
          {
            label: '下架',
            value: 2
          }
        ]
      }
    ]
  }

  sortList: IProductSort[] = []

  selectRows: ISaleStock[] = []

  // 编辑id
  editId = ""
  // 编辑弹窗
  editDialog = false
  // 批量编辑库存数据
  editStock = ''


  get ids () {
    return this.selectRows.map(item => item.productSkuId)
  }

  /**
   * 获取商品分类列表
   * @param status 显示状态
   */
   getSortList (status?: number) {
    return new Promise(resolve => {
      sortPageApi({status}).then(e => {
        this.sortList = e;
        resolve(e)
      })
    })
  }

  getSpDataValues (str: string) {
    let json: {key: string, value: string}[];

    try {
      json = JSON.parse(str || '[]')
    } catch (e) {
      json = []
    }

    return json.map(item => item.value).join(';')
  }

  getList () {
    this.tableLoading = true

    stockPageApi(this.queryParam).then(e => {
      this.tableData = e.list
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  validQty (qty: string) {
    // 判断是否不为0的无效字符
    if (!qty && isNaN(parseInt(qty))) {
      this.$message.error("可售库存不能为空")
      return false
    }
    // 判断qty是不是整数
    if (parseFloat(qty) % 1 !== 0) {
      this.$message.error("可售库存必须是整数")
      return false
    }
    // 判断负数
    if (parseFloat(qty) < 0) {
      this.$message.error("可售库存不能为负数")
      return false
    }
    if (parseFloat(qty) > 999999) {
      this.$message.error("可售库存不能超过999999")
      return false
    }
    return true
  }

  batchModifyStock (arr: string[], qty: string) {
    return new Promise((resolve, reject) => {
      stockModifyAPi(arr, parseInt(qty)).then(e => {
        if (e) {
          resolve(e)
        } else {
          reject()
        }
      }).catch(() => {
        reject()
      })
    })
  }

  openEdit (id: string) {
    if (this.editId) {
      return
    }
    this.editId = id;
  }

  cancelEdit () {
    this.editId = ''
    this.getList()
  }

  enterEdit (qty: string) {
    if (this.validQty(qty)) {
      this.batchModifyStock([this.editId], qty).then(e => {
        if (e) {
          this.cancelEdit()
          this.$message.success("操作成功!")
        }
      })
    }
  }

  addEnter (done: Function, loading: Function) {
    if (this.validQty(this.editStock)) {
      this.batchModifyStock(this.ids, this.editStock).then(() => {
        this.cancelEdit()
        this.$message.success("操作成功!")
        done()
      }).finally(() => {
        loading()
      })
    } else {
      loading()
    }
  }

  addClose () {
    this.editStock = ''
  }

  openBatch () {
    if (!this.selectRows.length) {
      return this.$message.error("请选择需要修改的库存数据")
    }
    this.editStock = ''
    this.editDialog = true
  }

  created () {
    this.getSortList()
    this.getList()
  }
}
