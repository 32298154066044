/**
 * @name: 销售管理-自营商品可售库存管理接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 销售管理-自营商品可售库存管理接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { ISaleStock, ISaleStockParams } from "./types";

export const stockPageApi = (params: ISaleStockParams) => get<IPageRes<ISaleStock[]>>('/admin/productSku/query/selfOperated/stock', params)

export const stockModifyAPi = (productSkuIds: string[], stock: number) => postJ('/admin/productSku/bulkModifyStock', {productSkuIds, stock})
